<template>
  <div ref="scroller" class="user-detail scroll-container" @scroll="onScroll">
    <van-nav-bar
      ref="nav-bar"
      slot="header"
      title="详情"
      :border="false"
      left-arrow
      fixed
      @click-left="$router.back()"
    />
    <div
      class="fix-user-info"
      :class="{ 'is-fixed': isFixed }"
      :style="{ height: isFixed ? `${fixTabsHeight}px` : 'auto' }"
    >
      <UserInfo
        ref="base-info"
        :style="{
          transform: `translateY(-${isFixed ? fixTabsOffsetTop : 0}px)`
        }"
        :data="data"
        :info-opacity="infoOpacity"
      >
        <UserTabs ref="tabs" v-model="componentName" />
      </UserInfo>
    </div>

    <div :style="{ height: `${fixElementHeight}px` }" />

    <component
      :is="componentName"
      :data="data"
      :health="healthArchives"
      :nav-bar-height="fixTabsHeight"
      class="user-detail-body"
      @add-element="addFixElement"
      @remove-element="removeFixElement"
    />
  </div>
</template>

<script>
import UserInfo from "./components/UserInfo.vue";
import UserTabs from "./components/UserTabs.vue";

// tabs componets
import BaseInfo from "./tabs/base-info/BaseInfo.vue";
import Qualification from "./tabs/qualification/Qualification.vue";
import LearningRecord from "./tabs/learning-record/LearningRecord.vue";
import OccupationalHealth from "./tabs/occupational-health/OccupationalHealth.vue";
import OccupationalHazard from "./tabs/occupational-hazard/OccupationalHazard.vue";
import LaborInsurance from "./tabs/labor-insurance/LaborInsurance.vue";
import CapabilityEvaluation from "./tabs/capability-evaluation/CapabilityEvaluation.vue";
import PersonalPoints from "./tabs/personal-points/PersonalPoints.vue";

import { getPersonDetail, getHealthArchives } from "../api";
export default {
  components: {
    UserInfo,
    UserTabs,
    BaseInfo,
    Qualification,
    LearningRecord,
    OccupationalHealth,
    OccupationalHazard,
    LaborInsurance,
    CapabilityEvaluation,
    PersonalPoints
  },
  data() {
    return {
      componentName: "BaseInfo",
      data: {},
      healthArchives: {},

      // 以下是滚动相关逻辑
      scrollTop: 0,
      navBarHeight: 0,
      fixElement: null,
      isFixed: false,
      tabsHeight: 0,
      tabsOffsetTop: 0,
      baseInfoHeight: 0
    };
  },
  computed: {
    fixElementHeight() {
      if (!this.isFixed) return 0;
      if (!this.fixElement) return this.baseInfoHeight;
      return this.fixElement.clientHeight + this.baseInfoHeight;
    },
    fixTabsHeight() {
      return this.tabsHeight + this.navBarHeight;
    },
    fixTabsOffsetTop() {
      return this.tabsOffsetTop - this.navBarHeight;
    },
    infoOpacity() {
      if (this.fixTabsOffsetTop <= 0) return 1;
      if (this.scrollTop > this.fixTabsOffsetTop) return 0;
      return 1 - this.scrollTop / this.fixTabsOffsetTop;
    }
  },
  created() {
    getPersonDetail(this.$route.params.id, this.userInfo.orgCode).then(res => {
      this.data = res || {};
    });

    getHealthArchives({
      userId: this.$route.params.id
    }).then(res => {
      this.healthArchives = res || {};
    });
  },
  mounted() {
    this.navBarHeight = this.$refs["nav-bar"].$el.clientHeight;
    this.tabsHeight = this.$refs.tabs.$el.clientHeight;
    this.tabsOffsetTop = this.$refs.tabs.$el.offsetTop;
    this.baseInfoHeight = this.$refs["base-info"].$el.clientHeight;
    if (this.fixElement) {
      this.fixElement.style.top = `${this.navBarHeight + this.tabsHeight}px`;
    }
    this.onScroll();
  },
  methods: {
    onScroll() {
      const target = this.$refs.scroller;
      if (!target) return;
      const scrollTop = target.scrollTop || 0;
      this.scrollTop = scrollTop;

      if (this.scrollTop > this.fixTabsOffsetTop) {
        this.fixElement.style.position = "fixed";
        this.isFixed = true;
      } else {
        this.fixElement.style.position = "static";
        this.isFixed = false;
      }
    },
    addFixElement(target) {
      if (!target) return;
      this.fixElement = target;
      this.fixElement.style.top = `${this.navBarHeight + this.tabsHeight}px`;
      this.onScroll();
    },
    removeFixElement() {
      this.fixElement = null;
      this.isFixed = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.fix-user-info {
  &.is-fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 100;
  }
}
.user-detail {
  height: 100%;
  overflow-y: auto;
  &-body {
    min-height: calc(100vh - 328px);
  }

  ::v-deep {
    .van-cell__title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8c8f97;
      line-height: 20px;
    }
    .van-cell__value {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 20px;
    }
  }

  ::v-deep {
    .van-tabs--line .van-tabs__wrap {
      margin-top: -4px;
      height: 34px;
      border: none;
    }
    .van-tabs__nav--line {
      padding-bottom: 0;
    }
    .van-tabs__line {
      bottom: 0;
    }

    .van-tab {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 16px;
    }
  }

  ::v-deep {
    .van-nav-bar {
      background: transparent;

      &.is-fixed {
        background: #ffffff;
      }
    }
  }
}
</style>
