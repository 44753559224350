<template>
  <div class="user-tab" @click="$emit('click')">
    <img class="user-tab-img" :src="src" :alt="text" />
    <div class="user-tab-text" :class="{ 'is-active': active }">{{ text }}</div>

    <div v-if="active" class="user-tab-arrow" />
  </div>
</template>

<script>
export default {
  props: {
    prop: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    activeImg: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    }
  },
  computed: {
    active() {
      return this.prop === this.value;
    },
    src() {
      return this.active ? this.activeImg : this.img;
    }
  }
};
</script>

<style lang="scss">
.user-tab {
  // width: 20%;
  padding: 0 16px;
  height: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &-img {
    width: 48px;
    height: 48px;
  }

  &-text {
    margin-top: 8px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 12px;
    &.is-active {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 12px;
    }
  }

  &-arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ffffff;
  }
}
</style>
