<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div class="illness-record">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="illness-record-item"
      >
        <UserCard :index="index" :title="item.illName">
          <van-cell-group>
            <van-cell title="诊断日期：" :value="item.checkDate || '--'" />
            <van-cell title="诊断单位：" :value="item.checkOrg || '--'" />
            <van-cell title="治疗结果：" :value="item.treatResult || '--'" />
            <van-cell title="备注：" :value="item.remark || '--'" />
          </van-cell-group>
          <div v-if="item.attachmentId" slot="footer">
            <a
              class="illness-record-link"
              :href="
                `${fileUrl}/file/download?downloadFlag=true&fileName=${item.attachmentId}`
              "
              :download="item.attachmentName"
              >{{ item.attachmentName }}
              <van-icon
                class-prefix="iconfont"
                class="link-icon iconfont-fujian"
              />
            </a>
          </div>
        </UserCard>
      </div>
    </div>
  </LoadListView>
</template>

<script>
import UserCard from "../../../components/UserCard.vue";
import { fileUrl } from "@/utils/constant";

export default {
  components: {
    UserCard
  },
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    },
    data: {
      type: Object,
      default: () => ({})
    },
    health: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    fileUrl: () => fileUrl
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.illness-record {
  padding: 16px;

  &-item + &-item {
    margin-top: 16px;
  }

  &-link {
    color: #1678ff;
    .link-icon {
      font-size: 12px;
      margin-left: 5px;
    }
  }
}
</style>
