<template>
  <div class="user-card">
    <div class="user-card-index">{{ no }}</div>
    <div v-if="tag" class="user-card-tag" :class="{ 'is-active': active }">
      {{ tag }}
    </div>
    <div class="user-card-header">
      <div class="user-card-title">
        <slot name="title">{{ title }}</slot>
      </div>
    </div>
    <div class="user-card-body">
      <slot />
    </div>
    <div v-if="$slots.footer" class="user-card-footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    index: {
      type: Number,
      default: 0
    },
    tag: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    no() {
      const val = (this.index + 1).toString();
      if (val[1]) return val;
      return `0${val}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.user-card {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 32px;
  padding-bottom: 5px;
  overflow: hidden;

  &-index {
    position: absolute;
    left: 8px;
    top: 19px;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #a0a2a6;
    line-height: 12px;
  }

  &-tag {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-right: 16px;
    padding-left: 12px;
    height: 30px;
    border-bottom-left-radius: 8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 14px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      display: block;
      height: 100%;
      width: 20px;
      transform: skewX(25deg);
      border-bottom-left-radius: 8px;
    }

    &.is-active {
      background: #d7e3fb;
      color: #1678ff;
      &::before {
        background: #d7e3fb;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
  }

  &-title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 18px;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-body {
    margin-top: 10px;
  }

  &-footer {
    padding: 10px 0 5px 0;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 12px;
  }

  .user-card-body {
    ::v-deep {
      .van-cell {
        padding: 5px 0;
        &::after {
          display: none;
        }
      }
      .van-cell-group {
        margin-bottom: 0;
        &::after {
          border-bottom-width: 1px;
          border-top-width: 0px;
        }
      }
      .van-cell__title {
        flex-grow: 0;
        white-space: nowrap;
        font-size: 12px;
      }
      .van-cell__value {
        text-align: left;
        font-size: 12px;
      }
    }
  }
}
</style>
