<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div class="train-list">
      <div v-for="(item, index) in list" :key="index" class="train-list-item">
        <UserCard
          :index="index"
          :title="item.planName"
          :tag="item.planStatus === 2 ? '已结束' : '进行中'"
          :active="item.planStatus !== 2"
        >
          <van-cell-group>
            <van-cell title="培训类别：" :value="item.trainTypeName || '--'" />
            <van-cell title="培训级别：" :value="item.trainLevelName || '--'" />
          </van-cell-group>
          <div slot="footer">
            <span>{{ item.beginTime }}</span>
            至
            <span>{{ item.endTime }}</span>
          </div>
        </UserCard>
      </div>
    </div>
  </LoadListView>
</template>

<script>
import UserCard from "../../../components/UserCard.vue";
export default {
  components: {
    UserCard
  },
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.train-list {
  padding: 16px;

  &-item + &-item {
    margin-top: 16px;
  }
}
</style>
