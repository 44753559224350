<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div class="labor-insurance-list">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="labor-insurance-list-item"
      >
        <UserCard
          :index="index"
          :title="item.labourName"
          :tag="item.signStatus ? '已签' : '未签'"
          :active="!item.signStatus"
        >
          <van-cell-group>
            <van-cell
              title="岗位："
              :value="health.positionNames || data.positionNames || '--'"
            />
            <van-cell title="数量：" :value="item.issueCount || '--'" />
            <van-cell title="型号：" :value="item.modelNum || '--'" />
            <van-cell title="防护类型：" :value="item.protectionName || '--'" />
            <van-cell title="日期：" :value="item.issueDate || '--'" />
          </van-cell-group>
          <div v-if="item.remark" slot="footer">备注：{{ item.remark }}</div>
        </UserCard>
      </div>
    </div>
  </LoadListView>
</template>

<script>
import UserCard from "../../../components/UserCard.vue";
export default {
  components: {
    UserCard
  },
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    },
    data: {
      type: Object,
      default: () => ({})
    },
    health: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.labor-insurance-list {
  padding: 16px;

  &-item + &-item {
    margin-top: 16px;
  }
}
</style>
