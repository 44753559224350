<template>
  <div class="personal-points">
    <div ref="fix-element" style="width: 100%; z-index: 100;">
      <UserTitle title="个人积分" />
      <van-tabs v-model="active">
        <van-tab title="HSE积分" name="Integral" />
        <van-tab title="教育培训" name="EducationTraining" />
      </van-tabs>
      <PersonalPointsSearch v-model="date" @search="onSearch" />

      <div class="personal-points-header">
        <div class="personal-points-title">积分明细</div>
        <span class="personal-points-point"
          >累计积分：<span style="color: #F53F3F;">{{ point }}</span></span
        >
      </div>
    </div>

    <component
      :is="active"
      ref="list"
      :api="api"
      @change-point="point = $event"
    />
  </div>
</template>

<script>
import UserTitle from "../../components/UserTitle.vue";

import PersonalPointsSearch from "./components/PersonalPointsSearch.vue";
import Integral from "./components/Integral.vue";
import EducationTraining from "./components/EducationTraining.vue";

import { getIntegral, getEducationTraining } from "../../../api";
export default {
  components: {
    UserTitle,
    Integral,
    EducationTraining,
    PersonalPointsSearch
  },
  data() {
    return {
      active: 0,
      date: {
        start: "",
        end: ""
      },
      point: 0
    };
  },
  computed: {
    api() {
      return this.active === "EducationTraining"
        ? this.getEducationTraining
        : this.getIntegral;
    }
  },
  mounted() {
    this.$emit("add-element", this.$refs["fix-element"]);
  },
  beforeDestroy() {
    this.$emit("remove-element");
  },
  methods: {
    onSearch() {
      this.$refs.list.getList(true);
    },
    getIntegral(query = {}) {
      return getIntegral({
        ...query,
        orgCode: this.userInfo.orgCode,
        startDate: this.date.start,
        endDate: this.date.end,
        userId: this.$route.params.id
      });
    },
    getEducationTraining(query = {}) {
      return getEducationTraining({
        ...query,
        orgCode: this.userInfo.orgCode,
        startDate: this.date.start,
        endDate: this.date.end,
        userId: this.$route.params.id,
        status: 3
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.personal-points {
  background: #eceef2;
  &-header {
    padding: 12px 16px 12px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #eceef2;
  }

  &-title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 14px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 14px;
      background: #2da6e9;
      margin-right: 4px;
    }
  }

  &-point {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 14px;
  }
}
</style>
