<template>
  <div>
    <van-search
      :value="value"
      left-icon=""
      readonly
      icon-prefix="iconfont"
      :placeholder="placeholder"
      class="user-search"
      @click="visible = true"
    >
      <template #right-icon>
        <van-icon name="zhankai" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>

    <SelectPopup
      :choose-value="value"
      :data="options"
      :title="title"
      :visible="visible"
      :value-key="valueKey"
      :label-key="labelKey"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
export default {
  components: {
    SelectPopup
  },
  props: {
    value: null,
    title: String,
    placeholder: String,
    options: {
      type: Array,
      default: () => []
    },
    // 选中的值的属性名
    valueKey: {
      type: String,
      default: "value"
    },
    // 在页面要展示的选项属性名
    labelKey: {
      type: String,
      default: "label"
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    onPopupConfirm(row) {
      this.$emit("input", row[this.valueKey]);
    },
    onPopupClose() {
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.user-search {
  padding: 0;
  ::v-deep {
    .van-search__content.van-search__content--square {
      height: 28px;
      background: #eceef2;
      border-radius: 4px;
      border: 1px solid #e1e3e8;
      margin: 0;
    }
  }
}
</style>
