<template>
  <div class="capability-evaluation">
    <div ref="fix-element" style="width: 100%; z-index: 100;">
      <UserTitle title="能力评价" />
      <DateList v-model="date" :dates="dates" @input="onChangeDate" />
    </div>
    <CapabilityEvaluationList
      :type="activeData.type"
      :list="evaluation.results"
    />
  </div>
</template>

<script>
import UserTitle from "../../components/UserTitle.vue";
import DateList from "./components/DateList.vue";
import CapabilityEvaluationList from "./components/CapabilityEvaluationList.vue";

const abilityStandard = {
  "-1": "不涉及",
  0: "A",
  1: "B",
  2: "C"
};

import {
  getCapabilityEvaluationDate,
  getCapabilityEvaluation,
  getPositionAssessment
} from "../../../api";

export default {
  components: {
    UserTitle,
    DateList,
    CapabilityEvaluationList
  },
  data() {
    return {
      date: "",
      dates: [],
      evaluation: {}
    };
  },
  computed: {
    activeData() {
      return this.dates.find(i => i.id === this.date) || {};
    }
  },
  created() {
    getCapabilityEvaluationDate({
      userId: this.$route.params.id,
      orgCode: this.userInfo.orgCode
    }).then(res => {
      this.dates = res || [];
      if (this.dates[0]) {
        this.date = this.dates[0].id;
        this.getCapabilityEvaluation();
      }
    });
  },
  mounted() {
    this.$emit("add-element", this.$refs["fix-element"]);
  },
  beforeDestroy() {
    this.$emit("remove-element");
  },
  methods: {
    onChangeDate() {
      this.getCapabilityEvaluation();
    },
    getCapabilityEvaluation() {
      const api =
        this.activeData.type === 1
          ? getCapabilityEvaluation
          : getPositionAssessment;
      return api(this.date, {
        orgCode: this.userInfo.orgCode
      }).then(res => {
        const results =
          this.activeData.type === 1 ? res.results : this.getResult(res);
        this.evaluation = { ...res, results };
      });
    },
    getResult(info) {
      let arr = [];
      info.assessmentTypes.map(v => {
        arr.push({
          abilityCategoryTypeName: v.positionStandardTypeName,
          standard: v.standard,
          directorResult: abilityStandard[v.directorResult]
        });
        if (v.assessmentElements && v.assessmentElements.length) {
          v.assessmentElements.map(k => {
            arr.push({
              abilityCategoryTypeName: v.positionStandardTypeName,
              abilityElementTypeName: k.standardElementName,
              standard: k.standard,
              selfResult: abilityStandard[k.selfResult],
              directorResult: abilityStandard[k.directorResult]
            });
          });
        }
      });
      return arr;
    }
  }
};
</script>

<style lang="scss" scoped>
.capability-evaluation {
  background: #eceef2;
}
</style>
