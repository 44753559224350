<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div class="curriculum-list">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="curriculum-list-item"
      >
        <UserCard
          :index="index"
          :title="item.name"
          :tag="item.finish ? '已完成' : '未完成'"
          :active="!!item.finish"
        >
          <van-cell-group>
            <van-cell
              title="课程类型："
              :value="item.assignmentType === 'COMPULSORY' ? '必修' : '选修'"
            />
            <van-cell title="课程分类：" :value="item.subjectName || '--'" />
            <van-cell
              title="完成课时："
              :value="`${item.studyhistoryNum}${item.lessionnum}`"
            />
            <van-cell title="学习时长：" :value="item.playTimeStr || '--'" />
          </van-cell-group>
          <div v-if="item.finishTime" slot="footer">
            <span>{{ item.finishTime }}</span>
            完成
          </div>
        </UserCard>
      </div>
    </div>
  </LoadListView>
</template>

<script>
import UserCard from "../../../components/UserCard.vue";
export default {
  components: {
    UserCard
  },
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.curriculum-list {
  padding: 16px;

  &-item + &-item {
    margin-top: 16px;
  }
}
</style>
