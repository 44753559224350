<template>
  <div class="user-licenses">
    <div ref="fix-element" style="width: 100%; z-index: 100;">
      <UserTitle title="专业资质" />
    </div>

    <div class="user-licenses-list">
      <div
        v-for="(item, index) in licenses"
        :key="index"
        class="user-licenses-item"
      >
        <div class="user-licenses-title">{{ item.typeName }}</div>
        <van-cell-group>
          <van-cell title="证号" :value="item.no || '--'" />
          <van-cell
            v-if="['8'].includes(item.type)"
            title="种类"
            :value="item.workTypeValue || '--'"
          />
          <van-cell
            v-if="['8'].includes(item.type)"
            title="作业项目"
            :value="item.projectTypeValue || '--'"
          />
          <van-cell
            v-if="['3'].includes(item.type)"
            title="作业类别"
            :value="item.workTypeValue || '--'"
          />
          <van-cell
            v-if="['3'].includes(item.type)"
            title="操作项目"
            :value="item.projectTypeValue || '--'"
          />
          <van-cell
            v-if="['5'].includes(item.type)"
            title="从业资格类别"
            :value="item.qualificationCategoryName || '--'"
          />
          <van-cell
            v-if="['1', '5'].includes(item.type)"
            title="准驾车型"
            :value="item.carTypeValues || '--'"
          />
          <van-cell
            v-if="
              ['2', '3', '9', '10'].includes(item.type) || +item.type > 1000000
            "
            title="下次复审日期"
            :value="item.reviewDate || '--'"
          />
          <van-cell title="初次领证日期" :value="item.getDate || '--'" />
          <van-cell title="发证机关" :value="item.office || '--'" />
          <van-cell title="有效期开始" :value="item.validStartDate || '--'" />
          <van-cell title="有效期结束" :value="item.validEndDate || '--'" />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import UserTitle from "../../components/UserTitle.vue";

export default {
  components: {
    UserTitle
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    licenses() {
      return this.data.licenses || [];
    }
  },
  mounted() {
    this.$emit("add-element", this.$refs["fix-element"]);
  },
  beforeDestroy() {
    this.$emit("remove-element");
  }
};
</script>

<style lang="scss" scoped>
.user-licenses {
  background: #eceef2;
  &-item {
    background: #ffffff;
    margin-bottom: 8px;
    padding-top: 24px;
  }

  &-title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 14px;
    display: flex;
    align-items: center;
    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 14px;
      background: #2da6e9;
      margin-right: 4px;
    }
  }
}
</style>
