<template>
  <div class="labor-insurance">
    <div ref="fix-element" style="width: 100%; z-index: 100;">
      <UserTitle title="劳保发放" />
    </div>

    <LaborInsuranceList
      :api="getLaborInsurance"
      :data="data"
      :health="health"
    />
  </div>
</template>

<script>
import UserTitle from "../../components/UserTitle.vue";
import LaborInsuranceList from "./components/LaborInsuranceList.vue";

import { getLaborInsurance } from "../../../api";

export default {
  components: {
    UserTitle,
    LaborInsuranceList
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    health: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      active: 0
    };
  },
  mounted() {
    this.$emit("add-element", this.$refs["fix-element"]);
  },
  beforeDestroy() {
    this.$emit("remove-element");
  },
  methods: {
    getLaborInsurance(query = {}) {
      return getLaborInsurance({
        ...query,
        orgCode: this.userInfo.orgCode,
        personId: this.$route.params.id,
        sort: "issueDate_desc",
        archivesId: this.health.archivesId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.labor-insurance {
  background: #eceef2;
}
</style>
