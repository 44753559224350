<template>
  <table class="hazard">
    <tr v-for="(tr, index) in list" :key="index" class="hazard-tr">
      <td
        v-for="(td, dIndex) in tr"
        :key="`${index}-${dIndex}`"
        class="hazard-td"
        :class="{
          'van-hairline--bottom': index !== list.length - 1,
          'van-hairline--left': dIndex >= 1
        }"
      >
        <div class="hazard-label">{{ td.keyName }}/{{ td.keyUnit }}</div>
        <div class="hazard-value">{{ td.recordValue }}</div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    list() {
      if (!this.data.length) return [];
      const list = [[]];
      this.data.forEach(item => {
        const last = list[list.length - 1];
        if (last && last.length < 2) {
          last.push(item);
        } else {
          list.push([item]);
        }
      });
      return list;
    }
  }
};
</script>

<style lang="scss" scoped>
.hazard {
  width: 100%;

  &-label {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 12px;
    margin: 8px 0;
  }

  &-value {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #15171b;
    line-height: 12px;
    margin-bottom: 10px;
  }

  &-td {
    width: 50%;
    &:nth-of-type(n + 2) {
      padding-left: 14px;
    }
  }
}
</style>
