<template>
  <div class="user-info">
    <div class="user-info-box" :style="{ opacity: infoOpacity }">
      <div class="user-info-header">
        <div class="user-info-avatar">
          <img :src="avatarSrcSwitch" />
          <van-icon
            class-prefix="iconfont"
            class="sex-icon"
            :class="{
              'iconfont-xingbienv': data.sex === 0,
              'iconfont-xingbienan': data.sex === 1
            }"
          />
        </div>
        <div class="user-info-name">{{ data.name || "--" }}</div>
      </div>
      <div class="user-info-tips">
        <div class="user-info-tip">
          <div class="user-info-label">所属部门</div>
          <div class="user-info-value">{{ data.departmentName || "--" }}</div>
        </div>
        <div class="user-info-tip">
          <div class="user-info-label">工作岗位</div>
          <div class="user-info-value">{{ data.positionNames || "--" }}</div>
        </div>
        <div class="user-info-tip">
          <div class="user-info-label">联系电话</div>
          <div class="user-info-value">{{ data.mobile || "--" }}</div>
        </div>
      </div>
    </div>

    <div class="user-info-footer">
      <slot />
    </div>
  </div>
</template>

<script>
import defaultAvatar from "../images/person.png";
import { fileUrl } from "@/utils/constant";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    infoOpacity: {
      type: Number,
      default: 1
    }
  },
  computed: {
    avatarSrcSwitch() {
      const fileId = this.data.fileId;
      if (fileId) {
        return `${fileUrl}${fileId}`;
      }
      return defaultAvatar;
    }
  }
};
</script>

<style lang="scss" scoped>
.user-info {
  height: 328px;
  width: 100%;
  padding-top: 90px;
  box-sizing: border-box;
  background: url("../images/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 0;

  &-box {
    margin: 0 auto;
    width: 335px;
    height: 142px;
    background: url("../images/box-background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
  }

  &-header {
    height: 54px;
    padding: 0 21px;
    display: flex;
  }

  &-avatar {
    position: relative;
    flex-shrink: 0;
    height: 66px;
    width: 66px;
    border-radius: 33px;
    margin-top: -12px;
    img {
      height: 66px;
      width: 66px;
      border-radius: 33px;
    }

    .iconfont-xingbienv {
      color: #1676ff;
    }

    .iconfont-xingbienan {
      color: rgb(251, 113, 113);
    }

    .sex-icon {
      position: absolute;
      bottom: 2px;
      right: 2px;
    }
  }

  &-name {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2e2e4d;
    line-height: 16px;
    margin-top: 16px;
    margin-left: 21px;
  }

  &-tips {
    margin-top: 26px;
    display: flex;
    align-items: stretch;
    padding: 0 15px;
    box-sizing: border-box;
  }

  &-tip {
    flex-grow: 0;
    flex-shrink: 0;
    width: 33.3333%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-tip + &-tip {
    border-left: 1px solid #eceef2;
  }

  &-label {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8f97;
    line-height: 12px;
  }

  &-value {
    margin-top: 8px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2e2e4d;
    line-height: 12px;
    width: 100%;
    text-align: center;
    padding: 0 5px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
