<template>
  <div class="occupational-hazard">
    <div ref="fix-element" style="width: 100%; z-index: 100;">
      <UserTitle title="职业危害" />
      <div class="occupational-hazard-header">
        <HazardSearch v-model="year" :options="years" @search="onSearch" />
        <div class="occupational-hazard-tips">
          <span v-for="(item, index) in positions" :key="index">
            岗位：{{ item.positionName }} </span
          >，
          <span> 接触危害因素：{{ tables.length }} </span>
        </div>
      </div>

      <van-tabs v-model="active" @change="onTabChange">
        <van-tab
          v-for="(item, index) in tabs"
          :key="index"
          :title="item.name"
          :name="item.typeCode"
        />
      </van-tabs>
    </div>
    <HazardGroup
      v-if="activeTables.length"
      :groups="activeTables"
      :fix-height="fixHeight"
      :nav-bar-height="navBarHeight"
    />
  </div>
</template>

<script>
import UserTitle from "../../components/UserTitle.vue";

import HazardSearch from "./components/HazardSearch.vue";
import HazardGroup from "./components/HazardGroup.vue";
import uniqBy from "lodash/uniqBy";

import {
  getHazardYears,
  getOccupationalHazard,
  getHazardData,
  getHazardItemList
} from "../../../api";
export default {
  components: {
    UserTitle,
    HazardSearch,
    HazardGroup
  },
  props: {
    navBarHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fixHeight: 0,
      active: "",
      year: "",
      years: [],
      tabs: [],
      tables: [],
      itemList: []
    };
  },
  computed: {
    positions() {
      return uniqBy(this.tables || [], "positionId");
    },
    activeTab() {
      return this.tabs.find(i => i.typeCode === this.active) || {};
    },
    configIds() {
      const children = this.activeTab.children || [];
      return children.map(i => i.id).join(",");
    },
    activeTables() {
      const groups = this.activeTab.children || [];

      if (!this.tables.length || !this.itemList.length)
        return groups.map(i => ({ name: i.name, list: [] }));

      const tables = new Map();
      this.tables.forEach(item => {
        const data = tables.get(item.configId) || [];
        data.push(item);
        tables.set(item.configId, data);
      });
      const itemMapList = this.itemList.map(item => {
        return {
          configId: item.configId,
          map: new Map(item.contentItemList.map(i => [i.id, i]))
        };
      });
      const itemList = new Map(
        itemMapList.map(item => [item.configId, item.map])
      );

      return groups.map(group => {
        const groupData = tables.get(group.id) || [];
        const listMap = itemList.get(group.id) || new Map();
        const list = groupData.map(row => {
          return {
            ...row,
            list: row.itemList
              .filter(item => listMap.has(item.contentItemId))
              .map(item => {
                const itemData = listMap.get(item.contentItemId);
                return {
                  contentItemId: item.contentItemId,
                  recordValue: item.recordValue,
                  keyName: itemData.keyName,
                  keyUnit: itemData.keyUnit
                };
              })
          };
        });
        return {
          id: group.id,
          name: group.name,
          itemList: group.itemList,
          limitsUnit: group.limitsUnit,
          list: list
        };
      });
    }
  },
  created() {
    Promise.all([
      getHazardYears({
        orgCode: this.userInfo.orgCode,
        sort: "year_desc"
      }),
      getOccupationalHazard({
        orgCode: this.userInfo.orgCode
      })
    ]).then(([years, tabs]) => {
      this.years = years || [];
      if (this.years[0]) {
        this.year = this.years[0].year;
      }

      this.tabs = tabs || [];
      if (this.tabs[0]) {
        this.active = this.tabs[0].typeCode;
      }

      this.getHazardData();
      this.getHazardItemList();
    });
  },
  mounted() {
    this.$emit("add-element", this.$refs["fix-element"]);
    this.fixHeight = this.$refs["fix-element"].clientHeight;
  },
  beforeDestroy() {
    this.$emit("remove-element");
  },
  methods: {
    getHazardData() {
      getHazardData({
        orgCode: this.userInfo.orgCode,
        userId: this.$route.params.id,
        year: this.year
      }).then(res => {
        this.tables = res;
      });
    },
    getHazardItemList() {
      getHazardItemList({
        orgCode: this.userInfo.orgCode,
        configIds: this.configIds,
        typeCode: this.active
      }).then(res => {
        this.itemList = res || [];
      });
    },
    onSearch() {
      this.getHazardData();
      this.getHazardItemList();
    },
    onTabChange() {
      this.onSearch();
    }
  }
};
</script>

<style lang="scss" scoped>
.occupational-hazard {
  background: #eceef2;
  &-tips {
    background: #ffffff;
    padding-bottom: 3px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 14px;
  }
}
</style>
