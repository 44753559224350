<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div class="examination-list">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="examination-list-item"
      >
        <UserCard
          :index="index"
          :title="item.paperName"
          :tag="item.status ? '未完成' : '已完成'"
          :active="!item.status"
        >
          <van-cell-group>
            <van-cell
              title="答题时长："
              :value="item.fmtTestTime ? `${item.fmtTestTime}分钟` : '--'"
            />

            <van-row>
              <van-col span="12">
                <van-cell title="考试总分：" :value="item.score || '--'" />
              </van-col>
              <van-col span="12">
                <van-cell title="及格分：" :value="item.passLine || '--'" />
              </van-col>
            </van-row>

            <van-row>
              <van-col span="12">
                <van-cell title="考试得分：" :value="item.userScore || '--'" />
              </van-col>
              <van-col span="12">
                <van-cell title="正确率：" :value="item.fmtAccuracy || '--'" />
              </van-col>
            </van-row>

            <van-cell title="考试结果：">
              <span :style="{ color: item.passed ? '#67C23A' : '#F53F3F' }">{{
                item.passed ? "及格" : "不及格"
              }}</span>
            </van-cell>
          </van-cell-group>
          <div v-if="item.addTime" slot="footer">
            <span>{{ item.addTime }}</span>
            交卷
          </div>
        </UserCard>
      </div>
    </div>
  </LoadListView>
</template>

<script>
import UserCard from "../../../components/UserCard.vue";
export default {
  components: {
    UserCard
  },
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.examination-list {
  padding: 16px;

  &-item + &-item {
    margin-top: 16px;
  }
}
</style>
