<template>
  <div class="hazard-item">
    <div
      :style="{
        height: fix ? `${headerHeight}px` : '0'
      }"
    />
    <div
      ref="header"
      class="hazard-item-header"
      :class="{ 'is-fixed': fix }"
      :style="{ top: `${fixedLine}px` }"
    >
      <div class="hazard-item-left">
        <span class="hazard-item-title">{{ data.name }}</span>
        <div class="hazard-item-tag-list">
          <span
            v-for="(i, index) in data.itemList"
            :key="index"
            class="hazard-item-tag"
            >接触限值：{{ i.limitsName }}{{ i.limitsValue
            }}{{ data.limitsUnit }}</span
          >
        </div>
      </div>
      <div
        v-if="data.list.length > 1"
        class="hazard-item-control"
        @click="$emit('input', data.id)"
      >
        {{ active ? "收起" : "展开" }}
      </div>
    </div>

    <div class="hazard-item-list">
      <UserCard
        v-for="(item, index) in renderList"
        :key="index"
        class="hazard-item-card"
        :index="index"
        :title="item.paperName"
        :tag="item.result ? '合格' : '不合格'"
        :active="!!item.result"
      >
        <span slot="title">
          {{ item.departmentName }}
          <span class="hazard-item-position">{{ item.positionName }}</span>
        </span>
        <van-cell-group>
          <van-cell title="测定地点：" :value="item.location || '--'" />
          <van-cell title="采样日期：" :value="item.samplingDate || '--'" />
        </van-cell-group>

        <HazardTable :data="item.list" />
      </UserCard>

      <div style="text-align: center;">
        <div v-if="active && hasMore" class="hazard-item-more" @click="addMore">
          加载更多
        </div>
      </div>

      <div v-if="!data.list.length" class="hazard-item-empty">
        <img class="hazard-item-img" src="../../../images/empty.png" alt="空" />
        <div class="hazard-item-text">还没有检测记录</div>
      </div>
    </div>
  </div>
</template>

<script>
import UserCard from "../../../components/UserCard.vue";
import HazardTable from "./HazardTable.vue";

export default {
  components: {
    UserCard,
    HazardTable
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    value: null,
    fixedLine: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      max: 5,
      headerHeight: 0,
      fix: false
    };
  },
  computed: {
    active() {
      return this.data.id === this.value;
    },
    renderList() {
      const list = this.data.list || [];
      if (!list.length) return [];
      if (!this.active) return [list[0]];
      return list.slice(0, this.max);
    },
    hasMore() {
      const list = this.data.list || [];
      return this.renderList.length < list.length;
    }
  },
  mounted() {
    this.headerHeight = this.$refs.header.clientHeight;
  },
  methods: {
    addMore() {
      this.max += 5;
    }
  }
};
</script>

<style lang="scss" scoped>
.hazard-item {
  &-header {
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    background: #eceef2;
    width: 100%;

    &.is-fixed {
      position: fixed;
    }
  }

  &-left {
    width: 300px;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &-title {
    flex-shrink: 0;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #15171b;
    line-height: 14px;
    display: inline-flex;
    align-items: center;
    margin-right: 6px;

    &::before {
      content: "";
      display: inline-block;
      margin-right: 4px;
      width: 12px;
      height: 14px;
      background: #2da6e9;
    }
  }

  &-tag-list {
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  &-tag {
    background: #43c09620;
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #43c096;
    line-height: 10px;
    margin-right: 2px;
    padding: 2px 4px;
  }

  &-control {
    flex-shrink: 0;
    margin-left: 5px;
    background: #f6faff;
    border-radius: 12px;
    border: 1px solid #e1e3e8;
    padding: 4px 14px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3b4664;
    line-height: 12px;
  }

  &-position {
    background: rgba(22, 120, 255, 0.1);
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1678ff;
    line-height: 10px;
    padding: 4px;
    transform: scale(0.8);
  }

  &-list {
    padding: 12px 16px;
    padding-top: 0px;
  }

  &-empty {
    width: 343px;
    height: 96px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &-img {
    height: 40px;
    margin-bottom: 14px;
  }
  &-text {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 12px;
  }

  &-card + &-card {
    margin-top: 16px;
  }

  &-more {
    display: inline-block;
    margin: 20px auto 0;
    padding: 4px 24px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3b4664;
    line-height: 12px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e1e3e8;
  }

  ::v-deep {
    .van-cell-group {
      &::after {
        border-bottom-width: 0 !important;
      }
    }
  }
}
</style>
