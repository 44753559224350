import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";

const { authorization, dictionary, neixunbao, psm } = apiPath;

export function getPersonDetail(id, orgCode) {
  return axios.get(`${authorization}//user/query/full/user/${id}`, {
    params: { orgCode }
  });
}

export function getCardTypes() {
  return axios.get(`${dictionary}/person/id/card/types`);
}

export function getSkillLevels() {
  return axios.get(`${dictionary}/person/skill/levels`);
}

export function getDegrees() {
  return axios.get(`${dictionary}/person/degree/types`);
}

export function getTrain(params) {
  return axios.get(`${neixunbao}/ty/page/user/plan/list`, { params });
}

export function getCurriculum(params) {
  return axios.get(`${neixunbao}/ty/page/user/course/list`, { params });
}

export function getExamination(params) {
  return axios.get(`${neixunbao}/ty/page/user/exam/list`, { params });
}

export function getHealthArchives(params) {
  return axios.get(`${psm}/profession/info/health-archives/user`, { params });
}

export function getContactRecord(params) {
  return axios.get(`${psm}/profession/page/profession-touch`, { params });
}

export function getHealthExamination(params) {
  return axios.get(`${psm}/profession/page/profession-check`, { params });
}

export function getIllnessRecord(params) {
  return axios.get(`${psm}/profession/page/profession-ill`, { params });
}

export function getHazardYears(params) {
  return axios.get(`${psm}/hazard-years`, { params });
}

export function getLaborInsurance(params) {
  return axios.get(`${psm}/page/profession-labour-yn`, { params });
}

export function getCapabilityEvaluationDate(params) {
  return axios.get(`${psm}/list/ability/position/user`, { params });
}

export function getCapabilityEvaluation(id, params) {
  return axios.get(`${psm}/info/ability/position/pre-assessment/${id}`, {
    params
  });
}

export function getPositionAssessment(id, params) {
  return axios.get(`${psm}/info/ability/position/assessment/${id}`, {
    params
  });
}

export function getIntegral(params) {
  return Promise.all([
    axios.get(`${psm}/page/hse/point`, { params }),
    axios.get(`${psm}/total/user/hse/point`, { params })
  ]);
}

export function getEducationTraining(params) {
  return Promise.all([
    axios.get(`${psm}/page/edu/train/point`, { params }),
    axios.get(`${psm}/total/user/edu/train/point`, { params })
  ]);
}

export function getOccupationalHazard(params) {
  return axios.get(`${psm}/tree/occupation/hazard/config/user`, params);
}

export function getHazardData(params) {
  return axios.get(`${psm}/list/occupation/year/record/user`, { params });
}

// Request URL: http://api-fat.tsingyun.net/psm/list/occupation/year/content/item?orgCode=YN2001002&configIds=316788614761545728,316793302395387904,322218125028229120,370355138063237120,370355172443947008,370355172443947009,425419947401805824,428803858039308288&typeCode=101

export function getHazardItemList(params) {
  return axios.get(`${psm}/list/occupation/year/content/item`, { params });
}
