<template>
  <div class="learning-record">
    <div ref="fix-element" style="width: 100%; z-index: 100;">
      <UserTitle title="学习记录" />

      <van-tabs v-model="active">
        <van-tab title="培训" name="Train" />
        <van-tab title="课程" name="Curriculum" />
        <van-tab title="考试" name="Examination" />
      </van-tabs>
    </div>

    <component :is="active" ref="list" :api="api" />
  </div>
</template>

<script>
import UserTitle from "../../components/UserTitle.vue";

import Train from "./components/Train.vue";
import Curriculum from "./components/Curriculum.vue";
import Examination from "./components/Examination.vue";

import { getTrain, getCurriculum, getExamination } from "../../../api";

export default {
  components: {
    UserTitle,
    Train,
    Curriculum,
    Examination
  },
  data() {
    return {
      active: "Train"
    };
  },
  computed: {
    apis() {
      return {
        Train: this.getTrain,
        Curriculum: this.getCurriculum,
        Examination: this.getExamination
      };
    },
    api() {
      return this.apis[this.active];
    }
  },
  mounted() {
    this.$emit("add-element", this.$refs["fix-element"]);
  },
  beforeDestroy() {
    this.$emit("remove-element");
  },
  methods: {
    getTrain(query = {}) {
      return getTrain({
        ...query,
        orgCode: this.userInfo.orgCode,
        id: this.$route.params.id
      });
    },
    getCurriculum(query = {}) {
      return getCurriculum({
        ...query,
        orgCode: this.userInfo.orgCode,
        id: this.$route.params.id
      });
    },
    getExamination(query = {}) {
      return getExamination({
        ...query,
        orgCode: this.userInfo.orgCode,
        id: this.$route.params.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.learning-record {
  background: #eceef2;
}
</style>
