<template>
  <div class="hazard-search">
    <UserSelect
      :value="value"
      class="hazard-search-picker"
      :options="options"
      value-key="year"
      label-key="year"
      title="检测年度"
      placeholder="检测年度"
      @input="$emit('input', $event)"
    />
    <van-button
      class="hazard-search-button confirm-btn"
      type="info"
      size="small"
      @click.prevent="$emit('search')"
      >查询</van-button
    >
  </div>
</template>

<script>
import UserSelect from "../../../components/UserSelect.vue";
export default {
  components: {
    UserSelect
  },
  props: {
    value: null,
    options: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.hazard-search {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 12px 16px;

  &::before {
    position: absolute;
    top: 0;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    border-bottom: 0.5px solid #c7c9d0;
    transform: scaleY(0.5);
  }

  &-picker {
    flex-grow: 1;
  }

  &-button {
    margin-left: 8px;
    height: 28px;
    background: #1678ff;
    border-radius: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
  }
}
</style>
