<template>
  <div class="hazard-groups">
    <HazardGroupitem
      v-for="(item, index) in groups"
      ref="groups"
      :key="index"
      v-model="active"
      :fixed-line="fixedLine"
      :data="item"
    />
  </div>
</template>

<script>
import HazardGroupitem from "./HazardGroupItem.vue";
export default {
  components: {
    HazardGroupitem
  },
  props: {
    groups: {
      type: Array,
      default: () => []
    },
    fixHeight: {
      type: Number,
      default: 0
    },
    navBarHeight: {
      type: Number,
      default: 0
    }
  },
  computed: {
    fixedLine() {
      return this.fixHeight + this.navBarHeight;
    }
  },
  watch: {
    groups() {
      if (this.groups[0]) {
        this.active = this.groups[0].id;
      } else {
        this.active = null;
      }
    },
    active() {
      this.$nextTick(() => {
        this.onScroll();
      });
    }
  },
  data() {
    return {
      active: null
    };
  },
  mounted() {
    this.scroller = document.querySelector(".user-detail.scroll-container");
    if (this.scroller) {
      this.scroller.addEventListener("scroll", this.onScroll);
    }
  },
  beforeDestroy() {
    if (this.scroller) {
      this.scroller.removeEventListener("scroll", this.onScroll);
    }
  },
  methods: {
    onScroll() {
      for (let i = 0; i < this.$refs.groups.length; i++) {
        const group = this.$refs.groups[i];
        const rect = group.$el.getBoundingClientRect();
        if (
          rect.top < this.fixedLine &&
          rect.top + rect.height - 50 > this.fixedLine
        ) {
          group.fix = true;
        } else {
          group.fix = false;
        }
      }
    }
  }
};
</script>
