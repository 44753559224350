<template>
  <div class="date-list">
    <div
      v-for="(item, index) in dates"
      :key="index"
      class="date-list-item"
      :class="{ 'is-active': value === item.id }"
      @click="$emit('input', item.id)"
    >
      <div class="date-list-date">{{ item.data && getDate(item.data) }}</div>
      <div class="date-list-name">{{ item.positionName }}</div>
      <div
        class="date-list-type"
        :class="{ 'is-blue': item.type !== 1, 'is-yellow': item.type === 1 }"
      >
        {{ item.type === 1 ? "岗前" : "定期" }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    value: null,
    dates: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getDate(date) {
      return dayjs(date).format("MM-DD");
    }
  }
};
</script>

<style lang="scss" scoped>
.date-list {
  background: #ffffff;
  position: relative;
  height: 74px;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::before {
    position: absolute;
    top: 0;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    border-bottom: 0.5px solid #c7c9d0;
    transform: scaleY(0.5);
  }

  &-item {
    display: inline-flex;
    overflow: hidden;
    width: 65px;
    height: 100%;
    margin: 0 16px;
    padding: 7px 0 13px 0;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.is-active {
      .date-list-date {
        background: #1678ff;
        color: #ffffff;
      }
      .date-list-name {
        color: #1678ff;
      }
    }
  }

  &-date {
    height: 16px;
    border-radius: 14px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8c8f97;
    line-height: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
  }

  &-name {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2e2e4d;
    line-height: 12px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  &-type {
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 10px;
    width: 44px;
    height: 16px;
    border-radius: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.is-blue {
      color: #1678ff;
      background: #dcebff;
    }
    &.is-yellow {
      color: #f3961c;
      background: #ffeed8;
    }
  }
}
</style>
