<template>
  <div class="base-info">
    <div class="base-info-card">
      <div class="base-info-cell">
        <div class="base-info-label">档案编号：</div>
        <div class="base-info-value">{{ data.archivesNum || "--" }}</div>
      </div>

      <div class="base-info-cell">
        <div class="base-info-label">建档时间：</div>
        <div class="base-info-value">{{ data.archivesCreateDate || "--" }}</div>
      </div>

      <div class="base-info-cell">
        <div class="base-info-label">调离时间：</div>
        <div class="base-info-value">{{ data.leaveDate || "--" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.base-info {
  padding: 16px;
  &-card {
    background: #ffffff;
    border-radius: 8px;
    padding: 30px 32px;
  }

  &-cell + &-cell {
    margin-top: 10px;
  }

  &-cell {
    display: flex;
    align-items: center;
  }

  &-label {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 12px;
  }

  &-value {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #15171b;
    line-height: 12px;
  }
}
</style>
