<template>
  <div class="occupational-health">
    <div ref="fix-element" style="width: 100%; z-index: 100;">
      <UserTitle title="职业健康" />
      <van-tabs v-model="active">
        <van-tab title="基本信息" />
        <van-tab title="职业接触史" />
        <van-tab title="既往病史" />
        <van-tab title="健康检查" />
      </van-tabs>
    </div>

    <BaseInfo v-if="active === 0" :data="health" />
    <ContactRecord v-if="active === 1" :api="getContactRecord" />
    <IllnessRecord v-if="active === 2" :api="getIllnessRecord" />
    <HealthExamination
      v-if="active === 3"
      :data="data"
      :health="health"
      :api="getHealthExamination"
    />
  </div>
</template>

<script>
import UserTitle from "../../components/UserTitle.vue";

import BaseInfo from "./components/BaseInfo.vue";
import ContactRecord from "./components/ContactRecord.vue";
import IllnessRecord from "./components/IllnessRecord.vue";
import HealthExamination from "./components/HealthExamination.vue";

import {
  getContactRecord,
  getHealthExamination,
  getIllnessRecord
} from "../../../api";

export default {
  components: {
    UserTitle,
    BaseInfo,
    ContactRecord,
    IllnessRecord,
    HealthExamination
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    health: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      active: 0
    };
  },
  mounted() {
    this.$emit("add-element", this.$refs["fix-element"]);
  },
  beforeDestroy() {
    this.$emit("remove-element");
  },
  methods: {
    getContactRecord(query = {}) {
      return getContactRecord({
        ...query,
        orgCode: this.userInfo.orgCode,
        archivesId: this.health.archivesId
      });
    },
    getHealthExamination(query = {}) {
      return getHealthExamination({
        ...query,
        orgCode: this.userInfo.orgCode,
        sort: "checkDate_desc"
      });
    },
    getIllnessRecord(query = {}) {
      return getIllnessRecord({
        ...query,
        orgCode: this.userInfo.orgCode,
        archivesId: this.health.archivesId,
        sort: "checkDate_desc"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.occupational-health {
  background: #eceef2;
}
</style>
