<template>
  <div>
    <div ref="fix-element" style="width: 100%; z-index: 100;">
      <UserTitle title="基础信息" />
    </div>
    <van-cell-group>
      <van-cell title="胸卡id" :value="data.bindCardNo || '--'" />
      <van-cell title="曾用名" :value="data.usedName || '--'" />
      <van-cell title="工号" :value="data.jobNum || '--'" />
      <van-cell title="性别" :value="data.sexValue || '--'" />
      <van-cell title="证件类型" :value="cardType" />
      <van-cell title="证件号码" :value="data.idCardNo || '--'" />
      <van-cell title="出生年月" :value="data.birthday || '--'" />
      <van-cell title="年龄" :value="data.age || '--'" />
      <van-cell title="专业" :value="data.major || '--'" />
      <van-cell title="最高学历" :value="degree" />
      <van-cell title="技能等级" :value="skillLevel" />
      <van-cell title="婚姻" :value="marriage" />
      <van-cell title="籍贯" :value="data.nativePlace || '--'" />
    </van-cell-group>
  </div>
</template>

<script>
import UserTitle from "../../components/UserTitle.vue";

import { getCardTypes, getSkillLevels, getDegrees } from "../../../api";
export default {
  components: {
    UserTitle
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cards: [],
      skillLevels: [],
      degrees: []
    };
  },
  computed: {
    marriages() {
      return [
        {
          label: "已婚",
          value: 2
        },
        {
          label: "未婚",
          value: 1
        }
      ];
    },
    cardType() {
      const card = this.cards.find(i => i.value === this.data.idCardType) || {};
      return card.label || "--";
    },
    degree() {
      const degree = this.degrees.find(i => i.value === this.data.degree) || {};
      return degree.label || "--";
    },
    skillLevel() {
      const level =
        this.skillLevels.find(i => i.value === `${this.data.skillLevel}`) || {};
      return level.label || "--";
    },
    marriage() {
      const marriage =
        this.marriages.find(i => i.value === this.data.marriage) || {};
      return marriage.label || "--";
    }
  },
  created() {
    // 证件类型列表
    getCardTypes().then(res => {
      this.cards = res || [];
    });

    // 获取学历
    getDegrees().then(res => {
      this.degrees = res;
    });

    // 获取技能等级
    getSkillLevels().then(res => {
      this.skillLevels = res || [];
    });
  },
  mounted() {
    this.$emit("add-element", this.$refs["fix-element"]);
  },
  beforeDestroy() {
    this.$emit("remove-element");
  }
};
</script>
