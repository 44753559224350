<template>
  <div class="education">
    <LoadListView
      v-model="loadStatus"
      :list="list"
      :total="total"
      :show-total-view="false"
      v-bind="$attrs"
      @load="getList"
    >
      <div class="education-list">
        <div v-for="(item, index) in list" :key="index" class="education-item">
          <UserCard :index="index" :title="item.content">
            <van-cell-group>
              <van-cell title="积分日期：" :value="item.pointDate || '--'" />
              <van-cell title="积分依据：" :value="item.classifyName || '--'" />
            </van-cell-group>
            <div slot="footer" class="education-footer">
              得分：
              <span class="education-score">{{ item.score || "--" }}</span>
            </div>
          </UserCard>
        </div>
      </div>
    </LoadListView>
  </div>
</template>

<script>
import UserCard from "../../../components/UserCard.vue";
export default {
  components: {
    UserCard
  },
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(([{ list = [], total = 0 }, point]) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.$emit("change-point", point);
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.education {
  &-list {
    padding: 0 16px 16px;
  }

  &-footer {
    display: flex;
    align-items: center;
  }

  &-score {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #67c23a;
    margin-left: 20px;
  }

  &-item + &-item {
    margin-top: 16px;
  }
}
</style>
