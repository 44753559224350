<template>
  <div class="user-tabs">
    <UserTab
      v-for="item in tabs"
      :key="item.prop"
      :prop="item.prop"
      :value="value"
      :img="item.img"
      :active-img="item.activeImg"
      :text="item.text"
      @click="onClick(item)"
    />
  </div>
</template>

<script>
import UserTab from "./UserTab.vue";
import baseInfo from "../images/base-info.png";
import baseInfoActive from "../images/base-info-active.png";
import qualification from "../images/qualification.png";
import qualificationActive from "../images/qualification-active.png";
import learningRecord from "../images/learning-record.png";
import learningRecordActive from "../images/learning-record-active.png";
import occupationalHealth from "../images/occupational-health.png";
import occupationalHealthActive from "../images/occupational-health-active.png";
import occupationalHazard from "../images/occupational-hazard.png";
import occupationalHazardActive from "../images/occupational-hazard-active.png";
import laborInsurance from "../images/labor-insurance.png";
import laborInsuranceActive from "../images/labor-insurance-active.png";
import capabilityEvaluation from "../images/capability-evaluation.png";
import capabilityEvaluationActive from "../images/capability-evaluation-active.png";
import personalPoints from "../images/personal-points.png";
import personalPointsActive from "../images/personal-points-active.png";

const tabs = [
  {
    prop: "BaseInfo",
    text: "基本信息",
    img: baseInfo,
    activeImg: baseInfoActive
  },
  {
    prop: "Qualification",
    text: "专业资质",
    img: qualification,
    activeImg: qualificationActive
  },
  {
    prop: "LearningRecord",
    text: "学习记录",
    img: learningRecord,
    activeImg: learningRecordActive
  },
  {
    prop: "OccupationalHealth",
    text: "职业健康",
    img: occupationalHealth,
    activeImg: occupationalHealthActive
  },
  {
    prop: "OccupationalHazard",
    text: "职业危害",
    img: occupationalHazard,
    activeImg: occupationalHazardActive
  },
  {
    prop: "LaborInsurance",
    text: "劳保发放",
    img: laborInsurance,
    activeImg: laborInsuranceActive
  },
  {
    prop: "CapabilityEvaluation",
    text: "能力评价",
    img: capabilityEvaluation,
    activeImg: capabilityEvaluationActive
  },
  {
    prop: "PersonalPoints",
    text: "个人积分",
    img: personalPoints,
    activeImg: personalPointsActive
  }
];
export default {
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  components: {
    UserTab
  },
  computed: {
    tabs: () => tabs
  },
  methods: {
    onClick(item) {
      this.$emit("input", item.prop);
    }
  }
};
</script>

<style lang="scss" scoped>
.user-tabs {
  padding-top: 16px;
  font-size: 0;
  width: 100%;
  height: 80px;
  white-space: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
}
</style>
