<template>
  <div class="capability-evaluation-list">
    <LoadListView
      v-if="!list.length"
      :list="[]"
      status="success"
      :total="0"
      :show-total-view="false"
    />
    <div
      v-for="(item, index) in list"
      :key="index"
      class="capability-evaluation-list-item"
    >
      <UserCard
        :index="index"
        :title="
          type === 1 ? item.abilityTypeName : item.abilityCategoryTypeName
        "
      >
        <van-cell-group>
          <van-row v-if="type === 1">
            <van-col span="12">
              <van-cell title="岗位要求：">
                <span>{{ abilityStandard[item.standard] || "--" }}</span>
              </van-cell>
            </van-col>
            <van-col span="12">
              <van-cell title="能力评定：">
                <span>{{ abilityStandard[item.result] || "--" }}</span>
              </van-cell>
            </van-col>
          </van-row>

          <van-row v-if="type === 2">
            <van-col span="12">
              <van-cell title="能力要素：">
                <span>{{ item.abilityElementTypeName || "--" }}</span>
              </van-cell>
            </van-col>
            <van-col span="12">
              <van-cell title="类别：">
                <span>{{ item.type || "--" }}</span>
              </van-cell>
            </van-col>
          </van-row>

          <van-row v-if="type === 2">
            <van-col span="12">
              <van-cell title="岗位要求：">
                <span>{{ abilityStandard[item.standard] || "--" }}</span>
              </van-cell>
            </van-col>
            <van-col span="12">
              <van-cell title="自我评价：">
                <span>{{ item.selfResult || "--" }}</span>
              </van-cell>
            </van-col>
          </van-row>

          <van-cell v-if="type === 2" title="主管评价：">
            <span>{{ item.directorResult || "--" }}</span>
          </van-cell>
        </van-cell-group>

        <div v-if="type === 1 && item.remark" slot="footer">
          备注：{{ item.remark }}
        </div>
      </UserCard>
    </div>
  </div>
</template>

<script>
import UserCard from "../../../components/UserCard.vue";

const abilityStandard = {
  "-1": "不涉及",
  0: "A",
  1: "B",
  2: "C"
};

export default {
  components: {
    UserCard
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    type: {
      Number,
      default: 1
    }
  },
  computed: {
    abilityStandard: () => abilityStandard
  }
};
</script>

<style lang="scss" scoped>
.capability-evaluation-list {
  padding: 16px;

  &-item + &-item {
    margin-top: 16px;
  }
}
</style>
